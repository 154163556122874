import 'assets/styles/index.scss';
import React, { Suspense, useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Popups from '../components/Popups';
import ROUTES from '../constants/routes';
import { LanguageContext } from '../hooks/LanguageContext';
import { TranslationsContext } from '../hooks/TranslationsContext';
import Disclaimer from './Disclaimer';

import Exchange from './Exchange';
import FAQ from './FAQ';
import Home from './Home';
import Policy from './Policy';
import Stake from './Stake';
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects';
import SwapHistory from './SwapHistory';
import Terms from './Terms';
import TokenCreator from './TokenCreator';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;

  & > div:first-child:has(+ div[height|='fit-content']) {
    & > div {
      padding: 10px;
      background-color: #100f0f;
      border-radius: 16px;

      & > svg {
        stroke: #ffffff;
      }

      & > div:nth-child(2) {
        & > div:first-child svg {
          stroke: #ff136d;
        }

        & > div:last-child {
          div,
          a {
            color: #ffffff;
          }
        }
      }

      & > div:last-child {
        background: linear-gradient(264.11deg, #ff136d -2.87%, #443de9 105.13%) !important;
      }
    }
  }

  & > div[height|='fit-content'] {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;

    & > div > div {
      padding: 10px;
      background-color: #100f0f;
      border-radius: 16px;

      & > svg {
        stroke: #ffffff;
      }

      & > div:nth-child(2) {
        & > div:first-child > svg {
          stroke: #ff136d;
        }

        & > div:last-child {
          & > div,
          & > a {
            color: #ffffff;
          }

          & > div {
            width: 330px;
          }
        }
      }

      & > div:last-child {
        background: linear-gradient(264.11deg, #ff136d -2.87%, #443de9 105.13%) !important;
      }
    }
  }
`;

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined);
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined);
  const [translations, setTranslations] = useState<Array<any>>([]);

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper>
          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Popups />
              <Switch>
                <Route exact strict path={ROUTES.TRADE} component={Exchange} />
                <Route exact strict path={ROUTES.STAKE} component={Stake} />
                <Route exact path={ROUTES.TOKEN_CREATOR} component={TokenCreator} />
                <Route exact path={ROUTES.HOME} component={Home} />
                <Route exact path={ROUTES.FAQ} component={FAQ} />
                <Route exact path={ROUTES.DISCLAIMER} component={Disclaimer} />
                <Route exact path={ROUTES.TERMS} component={Terms} />
                <Route exact path={ROUTES.POLICY} component={Policy} />
                <Route exact path={ROUTES.ALL_SWAPS} component={SwapHistory} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  );
}
